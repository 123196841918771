import React from "react";
import Layout from "../components/Layout";
import MaterialTable from 'material-table';
import tableIcons from "../components/shared/tableIcons";

const newsData = [
    { 
        title: 'Study findings show majority of older adults with cognitive impairment still drive', 
        source: 'News brief from Institute for Healthcare Policy and Innovation (IHPI)', 
        datePublished: 'July 20, 2023', 
        viewLink: 'https://ihpi.umich.edu/news/majority-older-adults-cognitive-impairment-still-drive',
        videoLink: null,
        videoLinkMobile: null
    },
    { 
        title: 'Judge Barbara Canales speaks about BASIC Cognitive', 
        source: 'Press conference at the Nueces County Commissioners Court', 
        datePublished: 'January 14, 2020', 
        viewLink: null,
        videoLink: 'https://www.facebook.com/plugins/video.php?height=440&href=https%3A%2F%2Fwww.facebook.com%2Fshinecorpuschristi%2Fvideos%2F2278498102447401%2F&show_text=false&width=750&t=0',
        videoLinkMobile: "https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fshinecorpuschristi%2Fvideos%2F2278498102447401%2F&width=313&show_text=false&height=176&appId"
    },{ 
        title: 'Cognitive study looking for local participants', 
        source: 'External Source from KRIS News', 
        datePublished: 'January 14, 2020', 
        viewLink: 'https://www.kristv.com/news/local-news/cognitive-study-looking-for-local-participants',
        videoLink: null,
        videoLinkMobile: null
    },{ 
        title: 'Harán estudios sobre pérdida de memoria en adultos mayores en Texas', 
        source: 'External Source from NoticiasYa (Univision)', 
        datePublished: 'September 6, 2018', 
        viewLink: 'https://noticiasya.com/corpus-christi/2018/09/06/haran-estudios-sobre-perdida-de-memoria-en-adultos-mayores-en-texas',
        videoLink: null,
        videoLinkMobile: null
    },{ 
        title: 'New Brain Study Will Look at Aging', 
        source: 'External Source from LabRoots', 
        datePublished: 'May 21, 2018', 
        viewLink: 'https://www.labroots.com/trending/neuroscience/8819/brain-study-aging',
        videoLink: null ,
        videoLinkMobile: null
    },{ 
        title: 'Nueces County B.A.S.I.C. cognitive research study', 
        source: 'External Source from KIII News', 
        datePublished: 'May 10, 2018', 
        viewLink: 'https://www.kiiitv.com/article/news/local/nueces-county-basic-cognitive-research-study/503-551112404s',
        videoLink: null,
        videoLinkMobile: null 
    },{ 
        title: 'Nueces County: New research study aims to learn about how the brain functions as people age', 
        source: 'External Source from KRIS News', 
        datePublished: 'May 10, 2018', 
        viewLink: null,
        videoLink: 'https://www.youtube.com/embed/q4lbXDmaf8U',
        videoLinkMobile: null
    },{ 
        title: 'University of Michigan focuses cognitive study on elderly residents in Nueces County', 
        source: 'External Source from Corpus Christi Caller Times', 
        datePublished: 'May 10, 2018', 
        viewLink: 'https://www.caller.com/story/news/local/2018/05/10/university-michigan-focuses-study-elderly-nueces-county-residents/598530002',
        videoLink: null,
        videoLinkMobile: null 
    },{ 
        title: 'El distrito de salud pública se asocia con la Universidad de Michigan para estudio de investigación', 
        source: 'Press Release from City of Corpus Christi Newsroom', 
        datePublished: 'May 10, 2018', 
        viewLink: 'http://news.cctexas.com/news/releases-20180510-6692580',
        videoLink: null,
        videoLinkMobile: null
    },{ 
        title: 'Dr. Lewis Morgenstern speaks about BASIC Cognitive Project-Nueces County Commissioners Court', 
        source: 'Source our youtube channel', 
        datePublished: 'August 22, 2018', 
        viewLink: null,
        videoLink: 'https://www.youtube.com/embed/cvpnE6VPBIM',
        videoLinkMobile: null
    },
];

const mapNewsData = (news, width) => {
    if(news.viewLink != null){
        return (
        <React.Fragment>
            <h2 className='newsDatePublished'>{news.datePublished}</h2>
            <h5>{news.source}</h5>
            <h5><a href={news.viewLink} target='_blank' rel='noopener noreferrer' className='callLink'>{news.title}</a></h5>
        </React.Fragment>)
    }else if(news.videoLink != null){
        if(news.videoLinkMobile == null || width > 768){
            return (
                <React.Fragment>
                    <h2 className='newsDatePublished'>{news.datePublished}</h2>
                    <h5>{news.title}</h5>
                    {/* <h4>{news.source}</h4> */}
                    <iframe 
                        className='responsiveIframe'
                        src={news.videoLink} 
                        title="YouTube video player" frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowfullscreen>
                    </iframe>
                </React.Fragment>
            )
        }else{
            return (
                <React.Fragment>
                    <h2 className='newsDatePublished'>{news.datePublished}</h2>
                    <h4>{news.title}</h4>
                    <iframe src={news.videoLinkMobile} width="313" height="176" style={{border:'none', overflow:'hidden'}} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                </React.Fragment>
            )
        }
    }
}


export default function News() {
    const isBrowser = () => typeof window !== "undefined";
    let width = isBrowser() && window.innerWidth;
    return (
        <Layout>
            <div justify-content='center' align-items='center'>
            <h1 style={{color:'#3399f3', marginTop:'30px'}}>BASIC Cognitive in the news</h1>
            <p style={{color:'#777'}}  className='faqContent'>Catch up on the latest updates about our study.</p>
            {/* <MaterialTable
                icons={tableIcons}
                columns={[
                    { title: 'Title of the ad', field: 'title' },
                    { title: 'Source name', field: 'source' },
                    { title: 'Date published', field: 'datePublished', type: 'numeric' },
                    { title: 'News Link', field: 'viewLink', render: rowData => <a href={rowData.viewLink} target="_blank" rel="noopener noreferrer" className='callLink'>View</a>}
                ]}
                data={newsData}
                title="Basic Cognitive news"
                options={{
                    paging: false
                }}
                style={{color:'#777'}}
            />  */}
            { newsData.map(news => mapNewsData(news, width)) }  
            <p style={{marginTop:'10px', color:'#777'}} className='faqContent'>For media inquiries contact Field Office Director Ruth Longoria at 361-826-1564.</p> 
            </div>
        </Layout>    
    )
}